import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'therapyStatus'})
export class TherapyStatusPipe implements PipeTransform {
    transform(isComplete: boolean, isScreening: boolean): string {
        let status = '';
        if (isScreening) {
            status = isComplete ? 'Y' : 'N';
        } else {
            status = isComplete ? 'Y' : 'N';
        }

        return status;
    }
}